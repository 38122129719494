import moment from "moment";
import React from "react";

import { useVarRef } from "./hooks";

const delay = (timeout) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

function useTimeoutProgress(timeout) {
  const start = React.useRef();
  const [value, setValue] = React.useState(1);
  React.useEffect(() => {
    const timeoutUpdateInterval = Number(timeout / 100);
    start.current = Date.now();
    let interval = setInterval(() => {
      const newValue = Math.max(0, 1 - (Date.now() - start.current) / timeout);
      setValue(newValue);
      if (newValue === 0) {
        clearInterval(interval);
        interval = null;
      }
    }, timeoutUpdateInterval);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timeout]);
  return value;
}

function useInterval(callback, delayMs) {
  const savedCallback = useVarRef(callback);
  React.useEffect(() => {
    if (!delayMs) return () => {};
    const id = setInterval(() => savedCallback.current(), delayMs);
    return () => clearInterval(id);
  }, [delayMs, savedCallback]);
}

function formatTime(time) {
  return time === null
    ? "-"
    : moment.utc(time).format("ddd, MMM D YYYY, HH:mm");
}

export { delay, useTimeoutProgress, useInterval, formatTime };
