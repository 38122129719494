import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import React from "react";
import { Button } from "react-bootstrap";

function ResetButton({ resetState = null }) {
  const { resetForm, handleSubmit } = useFormikContext();
  const clearFilters = () => {
    if (resetState) {
      // explicit way to resetForm
      resetState();
    } else {
      resetForm();
      handleSubmit();
    }
  };
  return (
    <Button variant="outline-danger" onClick={clearFilters}>
      <span className="me-2">
        <FontAwesomeIcon icon={faCircleXmark} />
      </span>
      Reset Filters
    </Button>
  );
}

export default ResetButton;
