import React from "react";
import { Row, Col } from "react-bootstrap";

import DateRange from "~/components/daterange";
import Form from "~/components/form";
import { dateToString } from "~/components/form/date-picker";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import ResetButton from "~/components/reset-button";
import { cachedAxios, ensureList, useSearchParams } from "~/utils";

import "./filters.scss";

async function filtersLoader() {
  const response = await cachedAxios("api/v2/anomaly-filters");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();

  const initialValues = {
    dateAfter: state.dateAfter ? new Date(state.dateAfter) : null,
    dateBefore: state.dateBefore ? new Date(state.dateBefore) : null,
    metric: ensureList(state.metric) || [],
    group: state.group || null,
  };

  const onChange = (values) => {
    setState({
      dateAfter: dateToString(values.dateAfter),
      dateBefore: dateToString(values.dateBefore),
      metric: values.metric,
      group: values.group || null,
      page: 1,
    });
  };
  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function FiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3">
        <FilterCol label="Alert">
          <Form.Select name="metric" options={options.metrics} isMulti />
        </FilterCol>
        <FilterCol label="Group">
          <Form.Select name="group" options={options.groups} isClearable />
        </FilterCol>
        <FilterCol label="Anomalies period">
          <DateRange name="date" />
        </FilterCol>
        <FilterCol className="filter__control">
          <div className="d-flex flex-row justify-content-end">
            <Col xs="auto" className="me-2">
              <ResetButton />
            </Col>
            <Col xs="auto">
              <SubmitButton icon={false} title="Apply" />
            </Col>
          </div>
        </FilterCol>
      </Row>
    </Form>
  );
}

function FilterCol({ children, label, className }) {
  return (
    <Col className={className} md={3} xs={6}>
      <Label className="filter__label" direction="vertical" text={label}>
        {children}
      </Label>
    </Col>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm };
