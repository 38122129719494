function formatComparison(interval) {
  if (interval == null) return "static value";
  return interval === 1 ? "1 day ago" : `${interval} days ago`;
}

function getLabel(field) {
  const capitalized = field.charAt(0).toUpperCase() + field.slice(1);
  return capitalized.replaceAll("_", " ");
}

export { formatComparison, getLabel };
