const defaultAlertTimeout = 5000;

function getEnvironmentName() {
  const url = window.location.href.toLowerCase();

  if (url.includes("staging")) {
    return ["STG", "STAGING"];
  }
  if (url.includes("localhost") || url.includes("127.0.0.1")) {
    return ["LCL", "LOCALHOST"];
  }
  return ["PRD", "PRODUCTION"];
}

export { getEnvironmentName, defaultAlertTimeout };
