/* eslint-disable camelcase */
import {
  faUserLarge,
  faHammer,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLoaderData } from "react-router-dom";

import { NavigationSection, NavigationExternalLink } from "./navigation-items";

function UserItemSet({ minimized }) {
  const { email, is_staff } = useLoaderData();
  return (
    <NavigationSection
      prefix="/account"
      icon={<FontAwesomeIcon icon={faUserLarge} />}
      title={email.split("@")[0]}
      minimized={minimized}
    >
      {is_staff && (
        <>
          <NavigationExternalLink
            minimized={minimized}
            to="/admin/"
            title="Admin"
            icon={<FontAwesomeIcon icon={faHammer} />}
          />
          <NavigationExternalLink
            samePage
            minimized={minimized}
            to="/flower/"
            title="Flower"
            icon={<FontAwesomeIcon icon={faHammer} />}
          />
        </>
      )}
      <NavigationExternalLink
        samePage
        minimized={minimized}
        to="/logout"
        title="Sign Out"
        icon={<FontAwesomeIcon icon={faRightFromBracket} />}
      />
    </NavigationSection>
  );
}

export default UserItemSet;
