import {
  faTimes,
  faCircleCheck,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Toast,
  ToastContainer,
  ProgressBar,
  Alert as BootstrapAlert,
} from "react-bootstrap";

import { defaultAlertTimeout } from "~/constants";
import { useTimeoutProgress } from "~/utils/time";

// translation from django flash message levels to bootstrap bg-color
const translation = {
  error: "danger",
  debug: "secondary",
};

const icons = {
  primary: faCircleInfo,
  secondary: faCircleInfo,
  success: faCircleCheck,
  danger: faTriangleExclamation,
  warning: faCircleExclamation,
  info: faCircleInfo,
  light: faCircleInfo,
  dark: faCircleInfo,
};

function useAlert() {
  const { addAlert } = React.useContext(alertsContext);
  return addAlert;
}

function AlertsContainer() {
  const { alerts, removeAlert } = React.useContext(alertsContext);
  if (alerts.length === 0) return false;
  return (
    <ToastContainer
      className="p-3"
      placement="top-end"
      style={{
        top: 0,
        right: 0,
        minWidth: 300,
        zIndex: 1100,
        position: "fixed",
      }}
    >
      {alerts.map((a) => (
        <AlertMessage key={a.id} onClose={() => removeAlert(a.id)} alert={a} />
      ))}
    </ToastContainer>
  );
}

function AlertMessage({ onClose, alert }) {
  const icon = icons[alert.variant];
  return (
    <Toast onClose={onClose}>
      <BootstrapAlert className="mb-0" variant={alert.variant}>
        <div className="d-flex align-items-center justify-content-between gap-2">
          {icon && <FontAwesomeIcon icon={icon} size="lg" />}
          <div className="fs-6 flex-fill">{alert.message}</div>
          <FontAwesomeIcon
            role="button"
            icon={faTimes}
            onClick={onClose}
            size="lg"
          />
        </div>
        {!!alert.timeout && alert.burnout && (
          <TimeoutProgress variant={alert.variant} timeout={alert.timeout} />
        )}
      </BootstrapAlert>
    </Toast>
  );
}

function TimeoutProgress({ variant, timeout }) {
  // bootstrap progress bar has transition set to 0.6s (--bs-progress-bar-transition var)for smooth visual updates
  // need to subtract that value from timeout for animation to end at proper time
  const timeoutProgress = useTimeoutProgress(timeout - 600);
  return (
    <ProgressBar
      className="mt-1"
      style={{ height: "3px" }}
      variant={variant}
      now={timeoutProgress * 100}
    />
  );
}

function AlertProvider({ children }) {
  const auth = useAlertsImplementation();
  return (
    <alertsContext.Provider value={auth}>
      <AlertsContainer />
      {children}
    </alertsContext.Provider>
  );
}

const alertsContext = React.createContext();

function useAlertsImplementation() {
  const nextId = React.useRef(1);
  const [alerts, setAlerts] = React.useState([]);

  const removeAlert = React.useCallback((id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((a) => a.id !== id));
  }, []);

  const addAlert = React.useCallback(
    (message, messageVariant, options = {}) => {
      const { timeout = defaultAlertTimeout } = options;
      const { burnout = false } = options;
      const variant = translation[messageVariant] || messageVariant;
      const entry = { message, variant, timeout, id: nextId.current, burnout };
      nextId.current += 1;
      setAlerts((prevAlerts) => [...prevAlerts, entry]);
      if (timeout) {
        setTimeout(() => {
          removeAlert(entry.id);
        }, timeout);
      }
    },
    [removeAlert]
  );

  return {
    addAlert,
    removeAlert,
    alerts,
  };
}

export { AlertProvider, useAlert };
