import React from "react";
import { useMatches } from "react-router-dom";

import { useIsRouteAllowed } from "~/base/routing";

function TitleChanger() {
  const matches = useMatches();
  const lastMatch = matches.filter((e) => e.handle && e.handle.title).at(-1);
  let title = lastMatch && lastMatch.handle.title;
  const isRouteAllowed = useIsRouteAllowed();
  if (typeof title === "function") {
    // prevent running of title function if user is not authorized
    // since it will most likely lead to errors and crash of page layout
    if (!isRouteAllowed) {
      title = null;
    } else {
      title = title(lastMatch.data);
    }
  }
  React.useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
}

export default TitleChanger;
