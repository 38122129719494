import moment from "moment";
import { Table } from "react-bootstrap";
import { useLoaderData, defer } from "react-router-dom";

import { PageFiltersForm, filtersLoader } from "~/blocks/anomaly-filters";
import {
  DeltaIndicator,
  formatSegment,
  AnomalyValues,
  getAnomalyKey,
  formatPredictionRange,
} from "~/blocks/anomaly-utils";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import { axios, extractSearch } from "~/utils";

function AnomalyListPage() {
  const { AnomaliesData, filtersPromise } = useLoaderData();
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
      />
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">Group</th>
            <th scope="col">Alert</th>
            <th scope="col">Dimensions</th>
            <th scope="col">Deviation %</th>
            <th scope="col">Deviation value</th>
            <th scope="col">Anomaly values (UTC)</th>
            <th scope="col">Prediction range</th>
            <th scope="col" style={{ width: "20%" }}>
              Anomaly Time (UTC)
            </th>
          </tr>
        </thead>
        <tbody>
          {AnomaliesData.results.map((anomaly) => (
            <tr key={getAnomalyKey(anomaly)} className="position-relative">
              <td>
                <b>{anomaly.metricGroup}</b>
              </td>
              <td>
                <b>{anomaly.metricName}</b>
              </td>
              <td>{formatSegment(anomaly.segment)}</td>
              <td>
                <DeltaIndicator anomaly={anomaly} />{" "}
                {anomaly.formattedPercentageDelta}
              </td>
              <td>
                <DeltaIndicator anomaly={anomaly} /> {anomaly.formattedDelta}
              </td>
              <td>
                {" "}
                <AnomalyValues anomaly={anomaly} />
              </td>
              <td>{formatPredictionRange(anomaly)}</td>
              <td>
                {moment.utc(anomaly.createdAt).format("ddd, MMM D YYYY, HH:mm")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={AnomaliesData} />
    </>
  );
}

AnomalyListPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const filtersPromise = filtersLoader();
  const anomaliesResponse = await axios(`api/v3/anomalies${search}`);
  return defer({
    AnomaliesData: anomaliesResponse.data,
    filtersPromise,
  });
};

export default AnomalyListPage;
