import { faBroom } from "@fortawesome/free-solid-svg-icons";
import React from "react";

import IconButton from "~/components/icon-button";

function SelectionHandler({ children }) {
  const contextValue = React.useContext(selectionContext);
  return children(contextValue);
}

function SelectionToggle({ item }) {
  const { isSelected, toggleSelection } = React.useContext(selectionContext);
  return (
    <input
      type="checkbox"
      className="form-check-input"
      checked={isSelected(item)}
      onChange={() => toggleSelection(item)}
    />
  );
}

function ClearSelectionButton() {
  const { setSelection, selection } = React.useContext(selectionContext);
  return (
    !!selection.length && (
      <IconButton
        size="sm"
        title="Clear Selection"
        icon={faBroom}
        onClick={() => setSelection([])}
      />
    )
  );
}

function PageSelectionToggle() {
  const { allState, toggleAllSelection } = React.useContext(selectionContext);
  const { indeterminate, checked } = allState;
  const cRef = React.useRef();
  React.useEffect(() => {
    cRef.current.indeterminate = indeterminate;
  }, [cRef, indeterminate]);

  return (
    <input
      ref={cRef}
      type="checkbox"
      className="form-check-input"
      checked={checked}
      onChange={toggleAllSelection}
    />
  );
}

function SelectionProvider({ currentPage, children }) {
  const controller = useSelectionImplementation(currentPage);
  return (
    <selectionContext.Provider value={controller}>
      {children}
    </selectionContext.Provider>
  );
}

function useSelectionImplementation(currentPage) {
  const [selection, setSelection] = React.useState([]);
  const ids = React.useMemo(
    () => Object.fromEntries(selection.map((item) => [item.id, item.id])),
    [selection]
  );
  const allState = React.useMemo(
    () => getAllState(currentPage, ids),
    [currentPage, ids]
  );

  const isSelected = (item) => item.id in ids;
  const toggleSelection = (item) => {
    const newSelection =
      item.id in ids
        ? selection.filter((v) => v.id !== item.id)
        : [...selection, item];
    setSelection(newSelection);
  };
  const toggleAllSelection = () => {
    let newSelection;
    if (allState.checked) {
      // the whole current page is selected, deselect all
      const toDeselect = Object.fromEntries(
        currentPage.map((item) => [item.id, item.id])
      );
      newSelection = selection.filter((v) => !(v.id in toDeselect));
    } else {
      // the whole current page is deselected, select all
      const toSelect = currentPage.filter((item) => !(item.id in ids));
      newSelection = [...selection, ...toSelect];
    }
    setSelection(newSelection);
  };
  return {
    selection,
    setSelection,
    currentPage,
    isSelected,
    toggleSelection,
    toggleAllSelection,
    allState,
  };
}

function getAllState(currentPage, ids) {
  let hasAll = true;
  let hasNone = true;
  currentPage.forEach((item) => {
    if (item.id in ids) {
      hasNone = false;
    } else {
      hasAll = false;
    }
  });
  if (hasAll) {
    return { checked: true, indeterminate: false };
  }
  if (hasNone) {
    return { checked: false, indeterminate: false };
  }
  return { checked: false, indeterminate: true };
}

const selectionContext = React.createContext();

export {
  SelectionProvider,
  SelectionHandler,
  PageSelectionToggle,
  SelectionToggle,
  ClearSelectionButton,
};
