import React from "react";
import { Col, Row } from "react-bootstrap";

import Form from "~/components/form";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import ResetButton from "~/components/reset-button";
import { cachedAxios, ensureList, useSearchParams } from "~/utils";

import { getLabel } from "./utils";

const activeOptions = [
  { value: "1", label: "yes" },
  { value: "0", label: "no" },
];

async function filtersLoader(metricId) {
  const response = await cachedAxios(
    `api/v2/metrics/${metricId}/segment-filters`
  );
  return response.data;
}

function getUrlField(field) {
  return `dim_${field}`;
}

function isSpecialDimension(field) {
  return field === "app_name" || field === "platform" || field === "placement";
}

function getInitial(field, state) {
  const urlField = getUrlField(field);
  const value = state[urlField] || null;
  return isSpecialDimension(field) ? ensureList(value) : value || "";
}

function PageFiltersForm({ dimensions, options, onFilterChanged }) {
  const { state, setState } = useSearchParams();
  const initialValues = Object.fromEntries(
    dimensions.map((field) => [field, getInitial(field, state)])
  );
  initialValues.enabled = state.enabled || null;
  const onChange = (values) => {
    const newState = Object.fromEntries(
      dimensions.map((field) => [getUrlField(field), values[field] || null])
    );
    newState.enabled = values.enabled || null;
    newState.page = 1;
    setState(newState);
    onFilterChanged();
  };
  return (
    <FiltersForm
      options={options}
      dimensions={dimensions}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function FiltersForm({ options, dimensions, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3">
        {dimensions.map((dim) => (
          <FilterCol key={dim} label={getLabel(dim)}>
            {isSpecialDimension(dim) ? (
              <Form.Select name={dim} options={options[dim]} isMulti />
            ) : (
              <Form.Control name={dim} />
            )}
          </FilterCol>
        ))}
        <FilterCol label="Active">
          <Form.Select name="enabled" options={activeOptions} isClearable />
        </FilterCol>
        <FilterCol className="ms-auto">
          <div className="d-flex flex-row justify-content-end">
            <Col xs="auto" className="me-2">
              <ResetButton />
            </Col>
            <Col xs="auto">
              <SubmitButton icon={false} title="Apply" />
            </Col>
          </div>
        </FilterCol>
      </Row>
    </Form>
  );
}

function FilterCol({ children, label, className }) {
  return (
    <Col md={3} xs={4} className={className}>
      <Label direction="vertical" text={label}>
        {children}
      </Label>
    </Col>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm };
