import classnames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";

import Form from "~/components/form";

import "./index.scss";

function NotificationRuleContainer({ name, configuration }) {
  const NotificationRuleElement = NotificationRuleElements[name][0];
  const { values } = useFormikContext();
  const ruleEnabled = values[name].enabled;
  return (
    <div className="mx-2 my-2">
      <div className="alert-setting inline-content mt-3">
        <NotificationRuleToggle notificationRuleName={name} />
        <div className={classnames({ disabled: !ruleEnabled })}>
          <NotificationRuleElement name={name} configuration={configuration} />
        </div>
      </div>
    </div>
  );
}

function DefaultNotificationRule({ name, configuration }) {
  const { values } = useFormikContext();
  const isSendAfterSelected =
    values[name].sendStrategy === "WAIT_FOR_ANOMALIES_COUNT";
  return (
    <div className="inline-content">
      <span>Any other anomaly, </span>
      <NotificationRuleStrategyDropdown
        notificationRuleName={name}
        strategiesOptions={configuration.sendStrategies}
      />
      {isSendAfterSelected && (
        <div className="inline-content">
          <p className="my-0">the</p>
          <NotificationRuleMaxAnomaliesDropdown notificationRuleName={name} />
          <p className="my-0"> anomaly</p>
        </div>
      )}
    </div>
  );
}

function DeltaValueDropNotificationRule({ name }) {
  return (
    <div className="inline-content">
      <span>When anomaly is less than</span>
      <NotificationRuleThresholdInput notificationRuleName={name} />
      <span>%, send immediately</span>
    </div>
  );
}

function DeltaValueIncreaseNotificationRule({ name }) {
  return (
    <div className="inline-content">
      <span>When anomaly is more than</span>
      <NotificationRuleThresholdInput notificationRuleName={name} />
      <span>%, send immediately</span>
    </div>
  );
}

function PredictionLowNotificationRule({ name }) {
  return (
    <div className="inline-content">
      <span>When anomaly is less than </span>
      <NotificationRuleThresholdInput notificationRuleName={name} />
      <span>% of the prediction low, send immediately</span>
    </div>
  );
}

function PredictionHighNotificationRule({ name }) {
  return (
    <div className="inline-content">
      <span>When anomaly is more than</span>
      <NotificationRuleThresholdInput notificationRuleName={name} />
      <span>% of the prediction high, send immediately</span>
    </div>
  );
}

function NotificationRuleThresholdInput({ notificationRuleName }) {
  const controlName = `${notificationRuleName}.threshold`;
  return (
    <div>
      <Form.Control name={controlName} />
      <Form.Control.ErrorFeedback name={controlName} />
    </div>
  );
}

function NotificationRuleMaxAnomaliesDropdown({ notificationRuleName }) {
  const controlName = `${notificationRuleName}.maxAnomaliesCount`;
  const statusOptions = [
    { label: "1st", value: 1 },
    { label: "2nd", value: 2 },
    { label: "3rd", value: 3 },
    { label: "4th", value: 4 },
    { label: "5th", value: 5 },
    { label: "6th", value: 6 },
    { label: "7th", value: 7 },
    { label: "8th", value: 8 },
    { label: "9th", value: 9 },
    { label: "10th", value: 10 },
  ];
  return (
    <div>
      <Form.Select name={controlName} options={statusOptions} />
      <Form.Control.ErrorFeedback name={controlName} />
    </div>
  );
}

function NotificationRuleToggle({ notificationRuleName }) {
  const controlName = `${notificationRuleName}.enabled`;
  return (
    <Form.Check
      type="switch"
      name={controlName}
      className="mx-2"
      id={controlName}
    />
  );
}

function NotificationRuleStrategyDropdown({
  notificationRuleName,
  strategiesOptions,
}) {
  const controlName = `${notificationRuleName}.sendStrategy`;

  return (
    <div>
      <Form.Select name={controlName} options={strategiesOptions} />
      <Form.Control.ErrorFeedback name={controlName} />
    </div>
  );
}

const nonDefaultConfigurationValidationElement = () =>
  Yup.object().shape({
    sendStrategy: Yup.string().nullable().notRequired(),
    threshold: Yup.number().when("enabled", {
      is: true,
      then: Yup.number()
        .min(0)
        .max(100)
        .required("Please specify threshold value"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    maxAnomaliesCount: Yup.number().nullable().notRequired(),
    enabled: Yup.boolean().required(),
  });

const defaultConfigurationValidationElement = () =>
  Yup.object().shape({
    sendStrategy: Yup.string().when("enabled", {
      is: true,
      then: Yup.string().nullable().notRequired(),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    threshold: Yup.number().nullable().notRequired(),
    maxAnomaliesCount: Yup.number()
      .nullable()
      .when(["sendStrategy", "enabled"], {
        is: (sendStrategy, enabled) =>
          sendStrategy === "WAIT_FOR_ANOMALIES_COUNT" && enabled === true,
        then: Yup.number()
          .min(1)
          .max(100)
          .required("Please specify number of anomalies"),
        otherwise: Yup.number().nullable().notRequired(),
      }),
    enabled: Yup.boolean().required(),
  });

const NotificationRuleElements = {
  default: [DefaultNotificationRule, defaultConfigurationValidationElement],
  delta_value_drop: [
    DeltaValueDropNotificationRule,
    nonDefaultConfigurationValidationElement,
  ],
  delta_value_increase: [
    DeltaValueIncreaseNotificationRule,
    nonDefaultConfigurationValidationElement,
  ],
  prediction_low: [
    PredictionLowNotificationRule,
    nonDefaultConfigurationValidationElement,
  ],
  prediction_high: [
    PredictionHighNotificationRule,
    nonDefaultConfigurationValidationElement,
  ],
};

function parseNotificationRulesInitialValue(notificationRulesData) {
  const notificationRulesInitialValues = {};

  notificationRulesData.forEach((item) => {
    const notificationConfigurationName = item.configuration.name.toLowerCase();
    notificationRulesInitialValues[notificationConfigurationName] = item;
  });
  return notificationRulesInitialValues;
}

function getNotificationRulesValidationElements(notificationRules) {
  const validationElements = {};
  notificationRules.forEach((name) => {
    validationElements[name] = NotificationRuleElements[name][1]();
  });
  return validationElements;
}

export {
  NotificationRuleContainer,
  parseNotificationRulesInitialValue,
  getNotificationRulesValidationElements,
};
