import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";

import DatePicker from "~/components/form/date-picker";

function DateRange({ after = "dateAfter", before = "dateBefore" }) {
  const { values } = useFormikContext();
  const startDate = values[after];
  const endDate = values[before];
  return (
    <Row>
      <Col xs={6}>
        <DatePicker
          name={after}
          placeholderText="From:"
          autoComplete="off"
          isClearable
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
        />
      </Col>
      <Col xs={6}>
        <DatePicker
          name={before}
          placeholderText="To:"
          autoComplete="off"
          isClearable
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />
      </Col>
    </Row>
  );
}

export default DateRange;
