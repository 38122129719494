import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useRef } from "react";

import { useLocalStorage } from "~/utils";

import Brand from "./brand";
import Navbar from "./navbar";
import { NavigationExternalLink, NavigationSection } from "./navigation-items";
import UserItemSet from "./user-item-sets";
import { useHasPermission } from "../user-data";
import "./sidebar.scss";

const SIDEBAR_MINIMIZED_LOCAL_STORAGE_VARIABLE = "sidebarMinimized";

function Separator() {
  return <hr className="mx-0" />;
}

function Sidebar() {
  const hasAnalyticsAccess = useHasPermission("analytics:analytics:edit");
  const [minimized, setMinimized] = useLocalStorage(
    SIDEBAR_MINIMIZED_LOCAL_STORAGE_VARIABLE,
    false
  );
  const navRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const recalculateToggleButtonPosition = (actualNavRef) => {
    const refNode = navRef.current || actualNavRef;
    if (!refNode) return;

    const buttonRef = toggleButtonRef.current;

    const navRefRect = refNode.getBoundingClientRect();
    const toggleButtonRefRect = buttonRef.getBoundingClientRect();

    const buttonLeft = navRefRect.width - toggleButtonRefRect.width / 2;

    buttonRef.style.position = "fixed";
    buttonRef.style.left = `${buttonLeft}px`;
  };

  return (
    <div className="sidebar__root">
      <button
        type="button"
        className="sidebar__minimize-button"
        onClick={() => setMinimized((v) => !v)}
        ref={toggleButtonRef}
      >
        <div
          className={classNames("sidebar__minimize-icon text-dark", {
            minimized,
          })}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
      </button>

      <nav
        className={classNames("sidebar__paper bg-light px-3", {
          minimized,
        })}
        ref={recalculateToggleButtonPosition}
      >
        <div className="sidebar__content">
          <Brand minimized={minimized} />
          <Separator />

          <div className="sidebar__overflow-section">
            <ul className="nav flex-column">
              <Navbar minimized={minimized} />
              {hasAnalyticsAccess && (
                <NavigationSection
                  key="/analytics-settings"
                  prefix="/analytics-settings"
                  title="Analytics"
                  minimized={minimized}
                >
                  <NavigationExternalLink
                    to="/analytics-settings/tiers/"
                    key="/analytics-settings/tiers/"
                    title="Tiers"
                    samePage
                    minimized={minimized}
                  />
                  <NavigationExternalLink
                    to="/analytics-settings/country-tiers/"
                    key="/analytics-settings/country-tiers/"
                    title="Country tiers"
                    samePage
                    minimized={minimized}
                  />
                  <NavigationExternalLink
                    to="/analytics-settings/carriers/"
                    key="/analytics-settings/carriers/"
                    title="Carriers"
                    samePage
                    minimized={minimized}
                  />
                  <NavigationExternalLink
                    to="/analytics-settings/preinstall-carriers/"
                    key="/analytics-settings/preinstall-carriers/"
                    title="Preinstall carriers"
                    samePage
                    minimized={minimized}
                  />
                </NavigationSection>
              )}
            </ul>
          </div>

          <div className="bg-light mt-auto mb-3">
            <Separator />

            <ul className="nav flex-column">
              <UserItemSet minimized={minimized} />
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
