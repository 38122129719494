import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Table } from "react-bootstrap";
import { useLoaderData, Link } from "react-router-dom";
import * as Yup from "yup";

import {
  DeltaIndicator,
  AnomalyValues,
  getAnomalyKey,
  formatPredictionRange,
} from "~/blocks/anomaly-utils";
import { IncidentStatus, SlackLink } from "~/blocks/incident-utils";
import Form from "~/components/form";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { axios, extractSearch, formatTime, useAlert } from "~/utils";

function IncidentDetailPage() {
  const { incident, incidentAnomalies, incidentFeedback } = useLoaderData();
  return (
    <>
      <PageHeader />
      <IncidentDetailsSection incident={incident} />
      <hr />
      <IncidentIvestigation incident={incident} />
      <hr />
      <AnomaliesTable anomalies={incidentAnomalies} />
      <Pagination data={incidentAnomalies} />
      <hr />
      <FeedbackTable feedback={incidentFeedback} />
    </>
  );
}

function IncidentIvestigation({ incident }) {
  const { investigation } = incident;
  const statusOptions = [
    { value: "TO_DO", label: "To Do" },
    { value: "INVESTIGATING", label: "Investigating" },
    { value: "REAL_ISSUE", label: "Real Issue" },
    { value: "NOTHING_FOUND", label: "Nothing Found" },
  ];
  const assigneeOptions = incident.assignees.map((assignee) => ({
    value: assignee.id,
    label: assignee.fullName,
  }));
  const initialValues = {
    status: investigation ? investigation.status : "TO_DO",
    assignee:
      investigation && investigation.assignee
        ? investigation.assignee.id
        : null,
    outcome: investigation ? investigation.outcome : null,
  };
  return (
    <>
      <h4>Investigation</h4>
      <IncidentInvestigationForm
        incident={incident}
        options={{ status: statusOptions, assignee: assigneeOptions }}
        initialValues={initialValues}
      />
    </>
  );
}

function IncidentInvestigationForm({ incident, options, initialValues }) {
  const validationSchema = Yup.object().shape({
    status: Yup.string().required(),
    assignee: Yup.number().nullable(),
    outcome: Yup.string().nullable().notRequired(),
  });
  const addAlert = useAlert();
  return (
    <div>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) =>
          axios({
            method: "PUT",
            url: `api/v1/incidents/${incident.id}/investigation`,
            data: values,
          })
            .then(() => {
              addAlert("Incident Investigation has been updated", "success");
            })
            .catch(() => {
              addAlert("Error while updating Incident Investigation", "error");
            })
        }
      >
        <Row className="mb-3">
          <Col xs={4}>
            <Label direction="vertical" text="Status">
              <Form.Select name="status" options={options.status} />
              <Form.Control.ErrorFeedback name="status" />
            </Label>
            <Label direction="vertical" text="Assignee" className="mt-3">
              <Form.Select name="assignee" options={options.assignee} />
              <Form.Control.ErrorFeedback name="assignee" />
            </Label>
          </Col>
          <Col xs={4}>
            <Label direction="vertical" text="Outcome">
              <Form.Control
                as="textarea"
                name="outcome"
                placeholder="Outcome"
                rows={5}
              />
              <Form.Control.ErrorFeedback name="outcome" />
            </Label>
          </Col>
        </Row>
        <Row>
          <Col xs={8} style={{ textAlign: "end" }}>
            <SubmitButton icon={false} />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

function IncidentDetailItem({ name, value }) {
  return (
    <Col xs={4}>
      <b>{name}</b>: {value}
    </Col>
  );
}

function IncidentDetailsSection({ incident }) {
  const incidentLinks = incident.slackLinks.map((link, i) => (
    <span key={link}>
      {i > 0 && ", "}
      <SlackLink url={link} />
    </span>
  ));
  return (
    <div className="fs-5 mt-4">
      <Row>
        <IncidentDetailItem name="ID" value={incident.id} />
        <IncidentDetailItem
          name="Status"
          value={<IncidentStatus status={incident.status} />}
        />
        <IncidentDetailItem
          name="Dimensions"
          value={Object.values(incident.segment).join(", ")}
        />
      </Row>
      <Row>
        <IncidentDetailItem
          name="Alert"
          value={
            <Link to={`/alerts/${incident.alertId}/segments`}>
              {incident.alertName}
            </Link>
          }
        />
        <IncidentDetailItem
          name="Started (UTC)"
          value={formatTime(incident.startTime)}
        />
        <IncidentDetailItem
          name="Number of anomalies"
          value={incident.anomaliesCount}
        />
      </Row>
      <Row>
        <IncidentDetailItem name="Group" value={incident.alertGroup} />
        <IncidentDetailItem
          name="Ended (UTC)"
          value={formatTime(incident.endTime)}
        />
        <IncidentDetailItem name="Slack Link" value={incidentLinks} />
      </Row>
    </div>
  );
}

function AnomaliesTable({ anomalies }) {
  return (
    <>
      <h4>Anomalies</h4>
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">Deviation %</th>
            <th scope="col">Deviation value</th>
            <th scope="col">Anomaly values (UTC)</th>
            <th scope="col">Prediction range</th>
            <th scope="col" style={{ width: "20%" }}>
              Anomaly Time (UTC)
            </th>
          </tr>
        </thead>
        <tbody>
          {anomalies.results.map((anomaly) => (
            <tr key={getAnomalyKey(anomaly)} className="position-relative">
              <td>
                <DeltaIndicator anomaly={anomaly} />{" "}
                {anomaly.formattedPercentageDelta}
              </td>
              <td>
                <DeltaIndicator anomaly={anomaly} /> {anomaly.formattedDelta}
              </td>
              <td>
                <AnomalyValues anomaly={anomaly} />
              </td>
              <td>{formatPredictionRange(anomaly)}</td>
              <td>{formatTime(anomaly.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function formatFeedback(reason, isGoodCatch) {
  const color = isGoodCatch ? "green" : "red";
  const icon = isGoodCatch ? faThumbsUp : faThumbsDown;
  return (
    <div className="d-flex flex-row">
      <FontAwesomeIcon icon={icon} style={{ color }} />
      <p className="mx-2">{reason}</p>
    </div>
  );
}

function FeedbackTable({ feedback }) {
  return (
    <>
      <h4 id="feedback-table">Feedback</h4>
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">User</th>
            <th scope="col">Feedback</th>
            <th scope="col">When</th>
          </tr>
        </thead>
        <tbody>
          {feedback.results.map((f) => (
            <tr key={f.id}>
              <td>{f.slackUsername}</td>
              <td>{formatFeedback(f.reason, f.feedback)}</td>
              <td>{formatTime(f.updatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

IncidentDetailPage.loader = async function loader({ params, request }) {
  const search = extractSearch(request.url);
  const [
    incidentResponse,
    incidentAnomaliesResponse,
    incidentFeedbackResponse,
  ] = await Promise.all([
    axios(`api/v3/incidents/${params.incidentID}`),
    axios(`api/v3/incidents/${params.incidentID}/anomalies${search}`),
    axios(`api/v1/incidents/${params.incidentID}/feedback`),
  ]);
  return {
    incident: incidentResponse.data,
    incidentAnomalies: incidentAnomaliesResponse.data,
    incidentFeedback: incidentFeedbackResponse.data,
  };
};

export default IncidentDetailPage;
