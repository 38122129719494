import Badge from "react-bootstrap/Badge";

function IncidentStatus({ status }) {
  const [formattedStatus, background] =
    status.toUpperCase() === "OPEN"
      ? ["Open", "danger"]
      : ["Closed", "secondary"];
  return <Badge bg={background}>{formattedStatus}</Badge>;
}

function SlackLink({ url }) {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        Link
      </a>
    );
  }
  return "-";
}

export { IncidentStatus, SlackLink };
