import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DeltaIndicator({ anomaly }) {
  if (!anomaly.delta) return null;
  if (anomaly.delta > 0)
    return <FontAwesomeIcon color="green" icon={faArrowUp} />;
  if (anomaly.delta < 0)
    return <FontAwesomeIcon color="red" icon={faArrowDown} />;
  return null;
}

function formatSegment(segment) {
  const values = Object.values(segment).join(", ");
  return `[${values}]`;
}

function formatPredictionRange(anomaly) {
  if (!anomaly.formattedPredictionLow || !anomaly.formattedPredictionHigh) {
    return "";
  }
  return `${anomaly.formattedPredictionLow} - ${anomaly.formattedPredictionHigh}`;
}

function AnomalyValues({ anomaly }) {
  return (
    <>
      {anomaly.formattedTimeFrame} = {anomaly.formattedValue}
      {anomaly.formattedPreviousTimeFrame && (
        <>
          <br />
          {anomaly.formattedPreviousTimeFrame} ={" "}
          {anomaly.formattedPreviousValue}
        </>
      )}
    </>
  );
}

function getAnomalyKey(anomaly) {
  return `${anomaly.metricId}-${JSON.stringify(anomaly.segment)}-${
    anomaly.createdAt
  }`;
}

export {
  DeltaIndicator,
  formatSegment,
  formatPredictionRange,
  AnomalyValues,
  getAnomalyKey,
};
