import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import {
  useLoaderData,
  useRevalidator,
  useLocation,
  Link,
  Outlet,
  useOutletContext,
} from "react-router-dom";

import useUserData from "~/base/user-data";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import { axios, extractSearch } from "~/utils";

import { SettingsTab } from "./alert-settings";
import EditSegmentModal, { BulkEditSegmentModal } from "./edit-segment-modal";
import { filtersLoader, PageFiltersForm } from "./segment-filters";
import {
  SelectionProvider,
  SelectionHandler,
  PageSelectionToggle,
  SelectionToggle,
  ClearSelectionButton,
} from "./selection";
import { formatComparison, getLabel } from "./utils";

function AlertDetailPage() {
  const { segments, alert, filters } = useLoaderData();
  const { pathname } = useLocation();
  const activeTab = pathname.includes("settings") ? "settings" : "segments";
  const revalidator = useRevalidator();
  return (
    <>
      <PageHeader />
      <AlertDataSection alert={alert} />
      <hr />
      <TabsMenu initialTabOpened={activeTab} />
      <Outlet
        context={{
          segments,
          alert,
          filters,
          reFetch: revalidator.revalidate,
        }}
      />
    </>
  );
}

function TabsMenu({ initialTabOpened }) {
  const [activeTab, setActiveTab] = React.useState(initialTabOpened);
  const handleSelect = (eventKey) => setActiveTab(eventKey);

  return (
    <Nav
      variant="tabs"
      defaultActiveKey="segments"
      activeKey={activeTab}
      onSelect={handleSelect}
      className="alert-details-tabs"
    >
      <Nav.Item>
        <Nav.Link eventKey="segments" as={Link} to="segments">
          Segments
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="settings" as={Link} to="settings">
          Settings
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
}

function AlertDataSection({ alert }) {
  const user = useUserData();
  const name = user.is_staff ? (
    <a href={`/admin/monitoring_configuration/metric/${alert.id}/change/`}>
      {alert.name}
    </a>
  ) : (
    alert.name
  );
  return (
    <div className="fs-5 mt-4">
      <Row>
        <Col xs={4}>
          <b>Name:</b> {name}
        </Col>
        <Col xs={4}>
          <b>Group:</b> {alert.group}
        </Col>
        <Col xs={4}>
          <b>Active:</b> <ActiveIndicator value={alert.enabled} />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <b>Comparison:</b> {formatComparison(alert.interval)}
        </Col>
        <Col xs={4}>
          <b>Window:</b> {alert.frequency}
        </Col>
        <Col xs={4}>
          <b>Dimensions:</b> {alert.dimensions.join(", ")}
        </Col>
      </Row>
      {alert.statefulIncident && (
        <Row>
          <Col xs={4}>
            <Link
              to={`/incidents?status=OPEN&ordering=-startTime&alertId=${alert.id}`}
            >
              View incidents...
            </Link>
          </Col>
        </Row>
      )}
    </div>
  );
}

function SegmentsSection() {
  const { segments, alert, filters, reFetch } = useOutletContext();
  const { dimensions } = alert;
  return (
    <SelectionProvider currentPage={segments.results}>
      <div className="border my-2 border-secondary p-2">
        <SelectionHandler>
          {({ setSelection }) => (
            <PageFiltersForm
              dimensions={alert.dimensions}
              options={filters}
              onFilterChanged={() => setSelection([])}
            />
          )}
        </SelectionHandler>
      </div>
      <Row className="my-2">
        <Col xs="auto">
          <SelectionHandler>
            {({ selection }) => (
              <span>
                {selection.length} selected out of {segments.count}{" "}
                <ClearSelectionButton />
              </span>
            )}
          </SelectionHandler>
        </Col>
        <Col xs="auto" className="ms-auto">
          <SelectionHandler>
            {({ selection, setSelection }) => (
              <BulkEditSegmentModal
                alert={alert}
                segments={selection}
                reFetch={reFetch}
                onEdit={() => setSelection([])}
              />
            )}
          </SelectionHandler>
        </Col>
      </Row>
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">
              <PageSelectionToggle />
            </th>
            {dimensions.map((v) => (
              <th key={v} scope="col">
                <span className="text-decoration-underline">{getLabel(v)}</span>
              </th>
            ))}
            <th scope="col">Min count</th>
            <th scope="col">Lower threshold</th>
            <th scope="col">Upper threshold</th>
            <th scope="col">Active</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {segments.results.map((segment) => (
            <tr key={segment.id}>
              <td>
                <SelectionToggle item={segment} />
              </td>
              {dimensions.map((v) => (
                <td key={v}>{segment.selectorSc[v]}</td>
              ))}
              <td>{segment.paramsSc.min_count}</td>
              <td>{segment.paramsSc.lower_delta_limit}</td>
              <td>{segment.paramsSc.upper_delta_limit}</td>
              <td>
                <ActiveIndicator value={segment.enabled} />
              </td>
              <td>
                <EditSegmentModal
                  alert={alert}
                  segment={segment}
                  reFetch={reFetch}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={segments} />
    </SelectionProvider>
  );
}

function ActiveIndicator({ value }) {
  return (
    <span className={value ? "text-success" : "text-muted"}>
      {value ? "yes" : "no"}
    </span>
  );
}

AlertDetailPage.loader = async function loader({ params, request }) {
  const search = extractSearch(request.url);
  const [alertResponse, segmentsResponse, filters] = await Promise.all([
    axios(`api/v2/alerts/${params.alertID}`),
    axios(`api/v2/alert-segments/${params.alertID}${search}`),
    filtersLoader(params.alertID),
  ]);

  return {
    alert: alertResponse.data,
    segments: segmentsResponse.data,
    filters,
  };
};

export default AlertDetailPage;
export { SegmentsSection, SettingsTab };
