import { Table } from "react-bootstrap";
import { useLoaderData, defer, Link } from "react-router-dom";

import { PageFiltersForm, filtersLoader } from "~/blocks/alert-filters";
import ActiveIndicator from "~/components/active-indicator";
import IconButton from "~/components/icon-button";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import SortableName from "~/components/sortable-name";
import { axios, extractSearch } from "~/utils";

function AlertListPage() {
  const { alertsData, filtersPromise } = useLoaderData();
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
      />
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">
              <SortableName
                displayName="Alert name"
                sortParamName="name"
                defaultSortOrder
              />
            </th>
            <th scope="col">
              <SortableName displayName="Group" sortParamName="group" />
            </th>
            <th scope="col">
              <SortableName displayName="Comparison" sortParamName="interval" />
            </th>
            <th scope="col">
              <SortableName
                displayName="Window"
                sortParamName="frequencyValue"
              />
            </th>
            <th scope="col">Dimensions</th>
            <th scope="col">Active</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {alertsData.results.map((alert) => (
            <tr key={alert.id} className="position-relative">
              <td>
                <b>{alert.name}</b>
              </td>
              <td>{alert.group}</td>
              <td>{formatComparison(alert.interval)}</td>
              <td>{alert.frequency}</td>
              <td>{alert.dimensions.join(", ")}</td>
              <td>
                <ActiveIndicator active={alert.enabled} />
              </td>
              <td>
                <Link to={`/alerts/${alert.id}/segments`}>
                  <IconButton.Edit />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={alertsData} />
    </>
  );
}

function formatComparison(interval) {
  if (interval == null) return "static value";
  return interval === 1 ? "1 day ago" : `${interval} days ago`;
}

AlertListPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const filtersPromise = filtersLoader();
  const alertsResponse = await axios(`api/v2/alerts${search}`);
  return defer({
    alertsData: alertsResponse.data,
    filtersPromise,
  });
};

export default AlertListPage;
