import classNames from "classnames";
import React from "react";
import { Card } from "react-bootstrap";

import Breadcrumb from "~/base/breadcrumb";

import "./page-header.scss";

function PageHeader({
  sticky = true,
  className,
  filter = false,
  children,
  title = null,
}) {
  return (
    <header
      className={classNames(className, {
        "sticky-top": sticky,
        "page-header__root-sticky": sticky,
      })}
    >
      <div className="d-flex flex-row justify-content-between align-items-start">
        <h4 className="mb-1">{title || <Breadcrumb />}</h4>
        <div className="d-flex justify-content-end">{children}</div>
      </div>

      <hr className="mt-2" />
      {filter && (
        <div>
          <Card className="mb-4">
            <Card.Body>{filter}</Card.Body>
          </Card>
        </div>
      )}
    </header>
  );
}

export default PageHeader;
