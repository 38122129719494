import { faCircleCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ActiveIndicator({ active }) {
  const icon = active ? faCircleCheck : faBan;
  return (
    <FontAwesomeIcon
      className="me-2"
      color={active ? "green" : "red"}
      icon={icon}
    />
  );
}

export default ActiveIndicator;
