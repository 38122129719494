import React from "react";
import { Row, Col } from "react-bootstrap";

import DateRange from "~/components/daterange";
import Form from "~/components/form";
import { dateToString } from "~/components/form/date-picker";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import ResetButton from "~/components/reset-button";
import { cachedAxios, useSearchParams, ensureList } from "~/utils";

import "./filters.scss";

async function filtersLoader() {
  const response = await cachedAxios("api/v2/incident-filters");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();

  const initialValues = {
    alert: ensureList(state.alert) || [],
    group: state.group || null,
    status: ensureList(state.status) || [],
    startTimeAfter: state.startTimeAfter
      ? new Date(state.startTimeAfter)
      : null,
    endTimeBefore: state.endTimeBefore ? new Date(state.endTimeBefore) : null,
  };

  const onChange = (values) => {
    setState({
      alert: values.alert,
      group: values.group || null,
      startTimeAfter: dateToString(values.startTimeAfter),
      endTimeBefore: dateToString(values.endTimeBefore),
      status: values.status,
      page: 1,
    });
  };

  const resetSearchState = () => {
    setState({
      alert: null,
      startTimeAfter: null,
      endTimeBefore: null,
      status: [],
      page: 1,
    });
  };

  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
      resetSearchState={resetSearchState}
    />
  );
}

function FiltersForm({ options, initialValues, onChange, resetSearchState }) {
  const statusOptions = [
    { value: "OPEN", label: "Open" },
    { value: "CLOSED", label: "Closed" },
  ];
  return (
    <Form
      initialValues={initialValues}
      syncSubmit
      onSubmit={onChange}
      enableReinitialize
    >
      <Row className="mb-3">
        <FilterCol label="Alert">
          <Form.Select name="alert" options={options.alerts} isMulti />
        </FilterCol>
        <FilterCol label="Group">
          <Form.Select name="group" options={options.groups} isClearable />
        </FilterCol>
        <FilterCol label="Incidents period">
          <DateRange after="startTimeAfter" before="endTimeBefore" />
        </FilterCol>
        <FilterCol label="Status">
          <Form.Select name="status" options={statusOptions} isMulti />
        </FilterCol>
        <FilterCol className="filter__control">
          <div className="d-flex flex-row justify-content-end">
            <Col xs="auto" className="me-2">
              <ResetButton resetState={resetSearchState} />
            </Col>
            <Col xs="auto">
              <SubmitButton icon={false} title="Apply" />
            </Col>
          </div>
        </FilterCol>
      </Row>
    </Form>
  );
}

function FilterCol({ children, label, className, md = 3, xs = 3 }) {
  return (
    <Col className={className} md={md} xs={xs}>
      <Label className="filter__label" direction="vertical" text={label}>
        {children}
      </Label>
    </Col>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm };
