import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { useSearchParams } from "~/utils";

function SortableName({ sortParamName, displayName, defaultSortOrder = null }) {
  const { state, setState } = useSearchParams();
  function getCurrentSortOrder(orderingParam) {
    if ([null, undefined].includes(orderingParam)) {
      return defaultSortOrder;
    }
    return !orderingParam.includes(sortParamName)
      ? null
      : orderingParam === sortParamName;
  }

  const sortOrder = getCurrentSortOrder(state.ordering);
  const handleSort = () => {
    let newOrderingParam;
    if (sortOrder === true) {
      newOrderingParam = `-${sortParamName}`;
    } else {
      newOrderingParam = sortParamName;
    }

    setState({
      ...state,
      ordering: newOrderingParam,
    });
  };

  const getSortIcon = () => {
    if (sortOrder === null) {
      return "";
    }
    if (sortOrder) {
      return <FontAwesomeIcon icon={faSortUp} />;
    }
    return <FontAwesomeIcon icon={faSortDown} />;
  };

  return (
    <span onClick={handleSort} style={{ cursor: "pointer" }}>
      {getSortIcon()} {displayName}
    </span>
  );
}

export default SortableName;
