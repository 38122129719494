import React from "react";
import Alert from "react-bootstrap/Alert";
import "./unauthorized-alert.scss";

function UnAuthorizedAlert() {
  const [showAlert, setShowAlert] = React.useState(true);
  const handleDismiss = () => setShowAlert(false);
  return (
    <Alert
      variant="danger"
      onClose={handleDismiss}
      show={showAlert}
      dismissible
      className="unauthorized-alert"
    >
      <p>You are not authorized to view this page</p>
    </Alert>
  );
}

export default UnAuthorizedAlert;
