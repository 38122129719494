import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { AuthStateContext, redirectToSSO } from "~/base/auth";
import { useIsRouteAllowed } from "~/base/routing";
import { SearchParamsProvider } from "~/base/search-params";
import TitleChanger from "~/base/title";
import { UserDataProvider } from "~/base/user-data";
import UnAuthorizedAlert from "~/components/unauthorized-alert";
import { cachedAxios } from "~/utils";

import Sidebar from "./sidebar";

// TODO: move SearchParamsProvider, useIsRouteAllowed, and CurrentPageTracker to Root element from layout
function Layout() {
  const { loggedIn } = React.useContext(AuthStateContext);
  const userData = useLoaderData();
  if (!loggedIn) {
    return redirectToSSO();
  }
  if (!userData.permissions.length) {
    return <AccessDeniedPage />;
  }
  return (
    <SearchParamsProvider>
      <UserDataProvider value={userData}>
        <div className="d-flex">
          <TitleChanger />
          <Sidebar />
          <main className="container-fluid pb-3">
            <ProtectedOutlet />
          </main>
        </div>
      </UserDataProvider>
    </SearchParamsProvider>
  );
}

Layout.loader = async function loader() {
  const response = await cachedAxios("api/user-info");
  return response.data;
};

function AccessDeniedPage() {
  return (
    <div className="mt-3 text-center">
      <h5>
        You don&apos;t have permission to access this portal.
        <br />
        Please reach out to &quot;Marketing and Data Products&quot; team to get
        the access to the portal.
      </h5>
    </div>
  );
}

function ProtectedOutlet() {
  const isRouteAllowed = useIsRouteAllowed();
  return isRouteAllowed ? <Outlet /> : <UnAuthorizedAlert />;
}

export default Layout;
