import { Row, Col, Form, Badge } from "react-bootstrap";
import { useLoaderData } from "react-router-dom";

import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

function MetricDetailPage() {
  const metric = useLoaderData();
  return (
    <>
      <PageHeader />
      <div className="mt-5">
        <LabeledRow label="Group">{metric.group}</LabeledRow>
        <LabeledRow label="Detector">{metric.detector}</LabeledRow>
        <LabeledRow label="Supported Frequencies">
          {metric.supportedFrequencies.map((v) => (
            <Badge key={v} className="mx-1" pill bg="secondary">
              {v}
            </Badge>
          ))}
        </LabeledRow>
        <LabeledRow label="Supported Dimensions">
          {metric.supportedDimensions.map((v) => (
            <Badge key={v} className="mx-1" pill bg="secondary">
              {v}
            </Badge>
          ))}
        </LabeledRow>
        <LabeledRow label="SQL File Name">{metric.sqlFilename}</LabeledRow>
        <LabeledRow label="Value">
          <Badge pill bg="secondary">
            {metric.valueColumn}
          </Badge>
        </LabeledRow>
        <LabeledRow label="Delay Time">{metric.delay} days</LabeledRow>
      </div>
    </>
  );
}

function LabeledRow({ label, children }) {
  return (
    <Row className="mb-3">
      <Form.Label column sm={2}>
        <b>{label}</b>
      </Form.Label>
      <Col sm={10}>{children}</Col>
    </Row>
  );
}

MetricDetailPage.loader = async function loader({ params }) {
  const response = await axios(`api/v2/metrics/${params.metricID}`);
  return response.data;
};

export default MetricDetailPage;
