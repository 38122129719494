import {
  faBell,
  faExplosion,
  faTriangleExclamation,
  // faGaugeHigh
} from "@fortawesome/free-solid-svg-icons";
import { createBrowserRouter, Navigate } from "react-router-dom";

import Layout from "~/base/layout";

import {
  HomePage,
  NotFoundPage,
  ErrorPage,
  AlertListPage,
  AlertDetailPage,
  // MetricListPage,
  // MetricDetailPage,
  AnomalyListPage,
  IncidentListPage,
  IncidentDetail,
  LogoutPage,
  AuthCallbackPage,
  AuthErrorPage,
  SegmentsSection,
  SettingsTab,
} from "./pages";

// handle.navbar.color should be in #ffffff format
const config = [
  // these 3 paths don't require user to be authenticated
  // and don't need to inherite base page layout
  {
    path: "logout",
    element: <LogoutPage />,
  },
  {
    path: "auth/sso/callback",
    loader: AuthCallbackPage.loader,
    element: <AuthCallbackPage />,
  },
  {
    path: "auth/error",
    element: <AuthErrorPage />,
  },
  // paths that require authentication / page layout
  {
    path: "/*",
    loader: Layout.loader,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <HomePage />,
            handle: {
              title: "OMG",
            },
          },
          {
            path: "alerts",
            handle: {
              crumb: "Alerts",
              title: "OMG: Alerts",
              requiredPermissions: "alerts:alerts:edit",
              navbar: {
                title: "Alerts",
                icon: faBell,
                defaultQueryPath: "ordering=name",
              },
            },
            children: [
              {
                index: true,
                loader: AlertListPage.loader,
                element: <AlertListPage />,
              },
              {
                path: ":alertID",
                loader: AlertDetailPage.loader,
                element: <AlertDetailPage />,
                handle: {
                  crumb: "Edit Alert",
                  title: (data) => `OMG: Alert ${data.alert.name}`,
                },
                children: [
                  {
                    index: true,
                    element: <Navigate to="segments" replace />,
                  },
                  {
                    path: "segments",
                    element: <SegmentsSection />,
                  },
                  {
                    path: "settings",
                    element: <SettingsTab />,
                  },
                ],
              },
            ],
          },
          // {
          //   path: "metrics",
          //   handle: {
          //     crumb: "Metrics",
          //     title: "OMG: Metrics",
          //     navbar: {
          //       title: "Metrics",
          //       icon: faGaugeHigh,
          //     },
          //   },
          //   children: [
          //     {
          //       index: true,
          //       loader: MetricListPage.loader,
          //       element: <MetricListPage />,
          //     },
          //     {
          //       path: ":metricID",
          //       loader: MetricDetailPage.loader,
          //       element: <MetricDetailPage />,
          //       handle: {
          //         getCrumbData: (data) => ({
          //           metricName: data.name,
          //         }),
          //         crumb: (crumbData) => crumbData.metricName || "Metric",
          //         title: (data) => `OMG: Metric ${data.name}`,
          //       },
          //     },
          //   ],
          // },
          {
            path: "anomalies",
            handle: {
              crumb: "Anomalies",
              title: "OMG: Anomalies",
              requiredPermissions: "anomalies:anomalies:view",
              navbar: {
                title: "Anomalies",
                icon: faTriangleExclamation,
              },
            },
            children: [
              {
                index: true,
                loader: AnomalyListPage.loader,
                element: <AnomalyListPage />,
              },
            ],
          },
          {
            path: "incidents",
            handle: {
              crumb: "Incidents",
              title: "OMG: Incidents",
              requiredPermissions: "incidents:incidents:view",
              navbar: {
                title: "Incidents",
                icon: faExplosion,
                defaultQueryPath: "status=OPEN&ordering=-startTime",
              },
            },
            children: [
              {
                index: true,
                loader: IncidentListPage.loader,
                element: <IncidentListPage />,
              },
              {
                path: ":incidentID",
                loader: IncidentDetail.loader,
                element: <IncidentDetail />,
                handle: {
                  getCrumbData: (data) => ({
                    incidentID: data.incident.id,
                  }),
                  crumb: ({ incidentID }) => `Incident ${incidentID}`,
                  title: (data) => `OMG: Incident ${data.incident.id}`,
                },
              },
            ],
          },
          {
            path: "*",
            element: <NotFoundPage />,
            handle: {
              crumb: "Page not found",
              title: "OMG: page not found",
            },
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(config);

export default router;
