import React from "react";

import { axios } from "~/utils";

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

function AuthProvider({ children }) {
  const user = localStorage.getItem("user");
  const initialState = {
    loggedIn: Boolean(user),
    user: JSON.parse(user),
  };

  function reducer(state, action) {
    switch (action.type) {
      case "login":
        localStorage.setItem("user", JSON.stringify(action.data));
        return {
          loggedIn: true,
          user: action.data,
        };
      case "logout":
        localStorage.removeItem("user");
        return {
          loggedIn: false,
          user: null,
        };
      default:
        throw Error(`Unknown action: ${action.type}`);
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

const OAUTH_STATE_KEY = "react-use-oauth2-state-key";

const saveOAuthState = (state) => {
  sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeOAuthSessionState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

const checkOAuthSessionState = (receivedState) => {
  const state = sessionStorage.getItem(OAUTH_STATE_KEY);
  return state && receivedState && state === receivedState;
};

function redirectToSSO() {
  axios("api/sso/callback-uri")
    .then((response) => {
      const { uri, state } = response.data;
      saveOAuthState(state);
      window.location.replace(uri);
    })
    .catch((error) => {
      /* eslint-disable-next-line no-console */
      console.log(error);
    });
}

export {
  AuthStateContext,
  AuthDispatchContext,
  AuthProvider,
  redirectToSSO,
  checkOAuthSessionState,
  removeOAuthSessionState,
};
