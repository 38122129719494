import { Table } from "react-bootstrap";
import { Link, useLoaderData } from "react-router-dom";

import PageHeader from "~/components/page-header";
import { axios } from "~/utils";

function MetricListPage() {
  const metrics = useLoaderData();
  return (
    <>
      <PageHeader />
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">Metric name</th>
            <th scope="col">group</th>
          </tr>
        </thead>
        <tbody>
          {metrics.map((metric) => (
            <tr key={metric.id} className="position-relative">
              <td>
                <Link
                  className="stretched-link text-black"
                  to={`/metrics/${metric.id}`}
                >
                  {metric.name}
                </Link>
              </td>
              <td>{metric.group}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

MetricListPage.loader = async function loader() {
  const response = await axios("api/v2/metrics");
  return response.data;
};

export default MetricListPage;
