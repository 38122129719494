import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";

import Form from "~/components/form";
import SubmitButton from "~/components/form/submit";
import { axios, useAlert, toOptions } from "~/utils";

import {
  NotificationRuleContainer,
  parseNotificationRulesInitialValue,
  getNotificationRulesValidationElements,
} from "./notification-rules";

import "./index.scss";

function SettingsTab() {
  const { alert } = useOutletContext();
  return (
    <div className="fs-5 mt-4 alert-settings-form">
      <AlertSettingsForm alert={alert} />
    </div>
  );
}

function AlertSettingsForm({ alert }) {
  const notificationRulesInitialValues = parseNotificationRulesInitialValue(
    alert.notificationRules
  );
  const initialValues = {
    ...notificationRulesInitialValues,
    statefulIncident: alert.statefulIncident,
    isReminderOn: !!alert.reminderAnomalyCount,
    reminderAnomalyCount:
      alert.reminderAnomalyCount || (alert.frequency === "4h" ? 2 : 6),
    periodsToCloseIncident: alert.periodsToCloseIncident || 1,
  };

  const addAlert = useAlert();

  const onSubmit = (values) => {
    const {
      statefulIncident,
      isReminderOn,
      periodsToCloseIncident,
      reminderAnomalyCount,
      ...notificationRules
    } = values;
    const isRuleSelected = (rule) => rule.threshold || rule.maxAnomaliesCount;
    const notificationRulesArray = Object.values(notificationRules)
      .filter(isRuleSelected)
      .map((value) => ({
        configuration: value.configuration.name.toUpperCase(),
        threshold: value.threshold,
        maxAnomaliesCount: value.maxAnomaliesCount,
        sendStrategy:
          value.sendStrategy || value.configuration.sendStrategies[0].value,
        enabled: value.enabled,
      }));
    const backendReminderAnomalyCount = isReminderOn
      ? reminderAnomalyCount
      : null;

    return axios({
      method: "PATCH",
      url: `api/v2/alerts/${alert.id}`,
      data: formRequestData(
        statefulIncident,
        backendReminderAnomalyCount,
        periodsToCloseIncident,
        notificationRulesArray
      ),
    })
      .then(() => {
        addAlert("Alert Settings were updated", "success");
      })
      .catch(() => {
        addAlert("Error while updating Alert settings", "error");
      });
  };

  const validationSchema = Yup.object().shape({
    statefulIncident: Yup.boolean(),
    isReminderOn: Yup.boolean(),
    reminderAnomalyCount: Yup.number().integer("Must be an integer!").min(1),
    periodsToCloseIncident: Yup.number().integer("Must be an integer!").min(1),
    ...getNotificationRulesValidationElements(
      Object.keys(notificationRulesInitialValues)
    ),
  });

  const notificationRuleElements = Object.entries(
    notificationRulesInitialValues
  ).map(([name, data]) => (
    <NotificationRuleContainer
      name={name}
      configuration={data.configuration}
      key={name}
    />
  ));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Row>
        <Col xs={9}>
          <Row className="my-2">
            <Col className="d-inline-flex align-items-start">
              <b className="mx-2">Stateful incident:</b>{" "}
              <Form.Check
                type="switch"
                name="statefulIncident"
                className="mx-2"
                id="statefulIncident"
              />
            </Col>
          </Row>
          <HiddenElement
            conditions={{
              statefulIncident: true,
            }}
          >
            <div className="stateful-incident-settings">
              <div className="setting-container">
                <div className="notification-rules-section">
                  <b className="setting-name">Notification Rules</b>
                  <p className="description my-1">
                    Start sending alerts based on the following rules:
                  </p>
                  <NotificationRulesHint
                    notificationRules={notificationRulesInitialValues}
                  />
                  <div className="alert-notification-rules">
                    {notificationRuleElements}
                  </div>
                </div>
              </div>
              <div className="setting-container">
                <b className="setting-name">Reminder</b> <ReminderRow />
              </div>
              <div className="setting-container">
                <b className="setting-name">Incident Closing Rule</b>
                <PeriodsToCloseIncidentRow />
              </div>
            </div>
          </HiddenElement>
        </Col>
        <Col>
          <SubmitButton title="Save" icon={false} className="mx-4" />
        </Col>
      </Row>
    </Form>
  );
}

function NotificationRulesHint({ notificationRules }) {
  const { values } = useFormikContext();
  if (
    Object.keys(notificationRules).every(
      (rule) => values[rule].enabled === false
    )
  ) {
    return (
      <div className="mx-3">
        <FontAwesomeIcon icon={faCircleInfo} size="sm" />
        <em className="hint mx-2">
          When all Notification Rules are disabled, all notifications will be
          sent immediately.
        </em>
      </div>
    );
  }
  return "";
}

function ReminderRow() {
  const { values } = useFormikContext();
  const { isReminderOn } = values;
  return (
    <div className="mx-2 my-2">
      <div className="alert-reminder-row">
        <div className="inline-content alert-setting">
          <Form.Check
            type="switch"
            name="isReminderOn"
            className="mx-2"
            id="isReminderOn"
          />
          <div
            className={classnames("inline-content", {
              disabled: !isReminderOn,
            })}
          >
            <span>Send a reminder every</span>
            <div>
              <Form.Control name="reminderAnomalyCount" />
              <Form.Control.ErrorFeedback name="reminderAnomalyCount" />
            </div>
          </div>
          <span>anomalies</span>
        </div>
      </div>
    </div>
  );
}

function PeriodsToCloseIncidentRow({ numOfPeriods = 24 }) {
  const periodOptions = toOptions(
    Array(numOfPeriods)
      .fill()
      .map((_, index) => index + 1)
  );
  const controlName = "periodsToCloseIncident";
  return (
    <div className="mx-2 my-2">
      <div className="inline-content alert-setting">
        <span>Close the incident after </span>
        <div>
          <Form.Select name={controlName} options={periodOptions} />
          <Form.Control.ErrorFeedback name={controlName} />
        </div>
        <span> period(s) without anomalies</span>
      </div>
    </div>
  );
}

function formRequestData(
  statefulIncident,
  reminderAnomalyCount,
  periodsToCloseIncident,
  notificationRules
) {
  const data = { statefulIncident };
  if (statefulIncident === true) {
    data.notificationRules = notificationRules;
    data.reminderAnomalyCount = reminderAnomalyCount;
    data.periodsToCloseIncident = periodsToCloseIncident;
  }
  return data;
}

function HiddenElement({ conditions, children }) {
  const { values } = useFormikContext();
  if (
    Object.keys(conditions).every(
      (condition) => values[condition] === conditions[condition]
    )
  ) {
    return children;
  }
  return "";
}

export { SettingsTab };
