import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ReactSelect from "react-select";
import "./pagination.scss";

import { toOptions, useSearchParams } from "~/utils";

const pageSizeOptions = toOptions([10, 25, 50, 100]);

function Pagination({
  data,
  namespace = null,
  defaultPageSize = 10,
  children = false,
}) {
  const { state, setState } = useSearchParams(namespace);
  const selectedPage = (state.page || 1) - 1;
  const onPageClick = (e) => setState({ page: e.selected + 1 });
  return (
    <Row className="align-items-center">
      <Col>
        <ReactPaginate
          forcePage={selectedPage}
          containerClassName="pagination mb-0"
          breakClassName="page-item"
          breakLabel={<Button className="page-link">...</Button>}
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={onPageClick}
          pageCount={data.numPages}
          disableInitialCallback
        />
      </Col>
      <Col xs="auto">{children}</Col>
      <Col xs="auto">
        <span>Items per page: </span>
      </Col>
      <Col xs={1}>
        <PageSizeSelector
          pageSize={state.pageSize || defaultPageSize}
          setPageSize={(v) => setState({ page: 1, pageSize: v })}
        />
      </Col>
    </Row>
  );
}

function PageSizeSelector({ pageSize, setPageSize }) {
  const valueObject = { value: pageSize, label: pageSize };
  const onChange = ({ value }) => setPageSize(value);
  return (
    <ReactSelect
      value={valueObject}
      onChange={onChange}
      options={pageSizeOptions}
      menuPlacement="auto"
    />
  );
}

export default Pagination;
