import { Navigate } from "react-router-dom";

import { useHasPermission } from "~/base/user-data";

function HomePage() {
  const hasAlertsAccess = useHasPermission("alerts:alerts:edit");
  return hasAlertsAccess ? <Navigate to="/alerts" replace /> : "";
}

export default HomePage;
