import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { makeUseAxios } from "axios-hooks";
import cookie from "cookie";

import { useInterval } from "~/utils/time";

const baseURL = "/monitoring-configuration";
const timeout = 1000 * 60 * 20;

const axiosInstance = axios.create({
  baseURL,
  timeout,
});
const cache = setupCache({
  maxAge: 5 * 60 * 1000,
});
const cachedAxiosInstance = axios.create({
  baseURL,
  timeout,
  adapter: cache.adapter,
});

[axiosInstance, cachedAxiosInstance].forEach((instance) => {
  instance.interceptors.request.use((config) => {
    const headers = config.headers || {};
    const cookies = cookie.parse(document.cookie);
    const { csrftoken } = cookies;
    headers["X-CSRFToken"] = csrftoken;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      headers.Authorization = `JWT ${user.token}`;
    }
    return { ...config, headers };
  });
  instance.interceptors.response.use(undefined, (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = "/logout";
        return new Promise(() => {});
      }
    }
    return Promise.reject(error);
  });
});

const privateUseAxios = makeUseAxios({
  axios: axiosInstance,
  defaultOptions: { useCache: false },
});

function AxiosProvider({ children }) {
  // might have some logic on authentication revamp, leave as is for now
  return children;
}

function useAxiosPolling(pollInterval, config, options) {
  const rv = privateUseAxios(config, options);
  const reFetch = rv[1];
  useInterval(reFetch, pollInterval);
  return rv;
}

export {
  AxiosProvider,
  axiosInstance,
  cachedAxiosInstance,
  privateUseAxios,
  useAxiosPolling as privateUseAxiosPolling,
};
