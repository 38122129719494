import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Table, Button } from "react-bootstrap";
import { useLoaderData, defer, Link } from "react-router-dom";

import { PageFiltersForm, filtersLoader } from "~/blocks/incident-filters";
import { IncidentStatus } from "~/blocks/incident-utils";
import PageHeader from "~/components/page-header";
import Pagination from "~/components/pagination";
import SimpleAwaiter from "~/components/simple-awaiter";
import SortableName from "~/components/sortable-name";
import { axios, extractSearch, formatTime } from "~/utils";

function IncidentListPage() {
  const { incidentsData, filtersPromise } = useLoaderData();
  return (
    <>
      <PageHeader
        filter={
          <SimpleAwaiter promise={filtersPromise}>
            {(filters) => <PageFiltersForm options={filters} />}
          </SimpleAwaiter>
        }
      />
      <Table striped hover>
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">
              <SortableName displayName="Group" sortParamName="alertGroup" />
            </th>
            <th scope="col">
              <SortableName displayName="Alert" sortParamName="alertName" />
            </th>
            <th scope="col">Dimensions</th>
            <th scope="col">
              <SortableName
                displayName="Incident started (UTC)"
                sortParamName="startTime"
                defaultSortOrder={false}
              />
            </th>
            <th scope="col">
              <SortableName
                displayName="Incident ended (UTC)"
                sortParamName="endTime"
              />
            </th>
            <th scope="col">
              <SortableName
                displayName="Number of anomalies"
                sortParamName="anomaliesCount"
              />
            </th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {incidentsData.results.map((incident) => (
            <tr key={incident.id}>
              <td>{incident.id}</td>
              <td>
                <b>{incident.alertGroup}</b>
              </td>
              <td>
                <b>{incident.alertName}</b>
              </td>
              <td>{formatSegment(incident.segment)}</td>
              <td>{formatTime(incident.startTime)}</td>
              <td>{formatTime(incident.endTime)}</td>
              <td>{incident.anomaliesCount}</td>
              <td>
                <IncidentStatus status={incident.status} />
              </td>
              <td>
                <Link to={`/incidents/${incident.id}`}>
                  <Button size="sm">
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination data={incidentsData} />
    </>
  );
}

function formatSegment(segment) {
  const values = Object.values(segment).join(", ");
  return `[${values}]`;
}
IncidentListPage.loader = async function loader({ request }) {
  const search = extractSearch(request.url);
  const filtersPromise = filtersLoader();
  const incidentsResponse = await axios(`api/v3/incidents${search}`);
  return defer({
    incidentsData: incidentsResponse.data,
    filtersPromise,
  });
};

export default IncidentListPage;
