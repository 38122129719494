import React from "react";
import { Row, Col } from "react-bootstrap";

import Form from "~/components/form";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import ResetButton from "~/components/reset-button";
import { cachedAxios, useSearchParams } from "~/utils";

import "./filters.scss";

async function filtersLoader() {
  const response = await cachedAxios("api/v2/alert-filters");
  return response.data;
}

function PageFiltersForm({ options }) {
  const { state, setState } = useSearchParams();

  const initialValues = {
    name: state.name || null,
    group: state.group || null,
    interval: state.interval || null,
    frequency: state.frequency || null,
    active: state.enabled !== "0",
  };

  const onChange = (values) => {
    setState({
      name: values.name || null,
      group: values.group || null,
      interval: values.interval || null,
      frequency: values.frequency || null,
      enabled: values.active ? null : "0",
      page: 1,
    });
  };
  return (
    <FiltersForm
      options={options}
      initialValues={initialValues}
      onChange={onChange}
    />
  );
}

function FiltersForm({ options, initialValues, onChange }) {
  return (
    <Form initialValues={initialValues} syncSubmit onSubmit={onChange}>
      <Row className="mb-3">
        <FilterCol label="Alert Name">
          <Form.Control name="name" />
        </FilterCol>
        <FilterCol label="Group">
          <Form.Select name="group" options={options.groups} isClearable />
        </FilterCol>
        <FilterCol label="Comparison">
          <Form.Select
            name="interval"
            options={options.intervals}
            isClearable
          />
        </FilterCol>
        <FilterCol label="Window">
          <Form.Select
            name="frequency"
            options={options.frequencies}
            isClearable
          />
        </FilterCol>
        <FilterCol>
          <div
            className="d-flex align-items-center mt-1"
            style={{ height: "40px" }}
          >
            <Form.Check
              name="active"
              label="Only active"
              id="alert-filter-active"
            />
          </div>
        </FilterCol>
        <FilterCol className="filter__control">
          <div className="d-flex flex-row justify-content-end">
            <Col xs="auto" className="me-2">
              <ResetButton />
            </Col>
            <Col xs="auto">
              <SubmitButton icon={false} title="Apply" />
            </Col>
          </div>
        </FilterCol>
      </Row>
    </Form>
  );
}

function FilterCol({ children, label, className }) {
  return (
    <Col className={className} md={2} xs={4}>
      <Label className="filter__label" direction="vertical" text={label}>
        {children}
      </Label>
    </Col>
  );
}

export { filtersLoader, PageFiltersForm, FiltersForm };
